import React, { useState, useContext, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import { useKeyPressEvent } from "react-use";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTitle } from "../components/context/page-title";
import { PageType } from "../components/context/page-type";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { VideoWithControls } from "../components/video/video-with-controls";
import { IssuuEmbed } from "../components/embeds/issuu-embed";

// SEO
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 50px 0 0 0;

  & a {
    border-bottom: 2px solid #000;
  }

  @media (max-width: 860px) {
    padding: 70px 0 0 0;
  }
`;

const MobilePageTitle = styled.div`
  display: none;
  margin: 0 0 2em 0;

  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  // grid-auto-flow: dense;
  // grid-column-gap: 30px;

  // & > div {
  //   grid-column: 3/11;

  //   & h1 {
  //     max-width: 875px;
  //     margin: 0 auto;
  //   }
  // }

  @media (max-width: 860px) {
    display: block;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: dense;
  grid-column-gap: 30px;

  margin: 0 0 120px 0;

  @media (max-width: 860px) {
    margin: 0 0 20px 0;
  }

  & .module-image-container,
  & .module-video-container {
    grid-column: 3/11;

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 860px) {
      grid-column: 1/13;
    }
  }

  & .module-text-container {
    grid-column: 1/13;

    & .text {
      max-width: 675px;
      margin: 0 auto;
    }

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  & .module-text-with-image-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: dense;
    grid-column-gap: 30px;

    grid-column: 1/13;

    @media (max-width: 767px) {
      display: block;
      grid-template-columns: unset;
      grid-auto-flow: unset;
      grid-column-gap: unset;
    }

    &.text-columns-1 {
      & .text-container {
        max-width: 675px;
      }

      &.image-left {
        & .image-container {
          grid-column: 1/4;
        }

        & .text-container {
          grid-column: 5/12;
        }
      }

      &.image-right {
        & .image-container {
          grid-column: 9/13;
        }

        & .text-container {
          grid-column: 1/7;
        }
      }
    }

    &.text-columns-2 {
      &.image-left {
        & .image-container {
          grid-column: 1/4;
        }

        & .text-container {
          grid-column: 4/13;
        }
      }

      &.image-right {
        & .image-container {
          grid-column: 9/13;
        }

        & .text-container {
          grid-column: 1/9;
        }
      }

      & .text-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;

        @media (max-width: 1024px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;
        }
      }
    }
  }
`;

const Article = ({ location, data }) => {
  const [journalFilters, setJournalFilters] = useState(null);
  const [pageTitle, setPageTitle] = useContext(PageTitle);
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageTitle(data.prismicArticle.data.title.text);
  }, [data]);

  useEffect(() => {
    setPageType(`article`);
  }, []);

  useEffect(() => {
    if (
      location !== undefined &&
      location !== null &&
      location.state !== undefined &&
      location.state !== null &&
      location.state.filters !== undefined &&
      location.state.filters !== null
    ) {
      setJournalFilters(location.state.filters);
    } else {
      setJournalFilters(null);
    }
  }, [location]);

  const backToJournal = () =>
    navigate("/journal", {
      state: { filters: journalFilters },
    });
  useKeyPressEvent("Esc", backToJournal);
  useKeyPressEvent("Escape", backToJournal);

  const content = data.prismicArticle.data.body.map((content, index) => {
    if (content.slice_type === "image") {
      return (
        <ContentContainer
          className="module-image"
          key={`single_project_item_${index}`}
        >
          <div className="module-image-container">
            {content.primary.image.fluid !== null && (
              <AspectRatioImageContainer image={content.primary.image}>
                <img
                  srcSet={content.primary.image.fluid.srcSetWebp}
                  src={content.primary.image.fluid.srcWebp}
                  alt={content.primary.image.alt}
                  loading={`lazy`}
                />
              </AspectRatioImageContainer>
            )}

            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.primary.caption.html,
              }}
            />
          </div>
        </ContentContainer>
      );
    }

    if (content.slice_type === "flipbook_embed") {
      return (
        <ContentContainer key={`single_item_${index}`} className="">
          <IssuuEmbed node={content.primary.embed_code} />
        </ContentContainer>
      );
    }

    if (content.slice_type === "video") {
      return (
        <ContentContainer
          key={`single_project_item_${index}`}
          className="module-video"
        >
          <div className="module-video-container">
            <VideoWithControls video={content.primary.video} />

            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.primary.caption.html,
              }}
            />
          </div>
        </ContentContainer>
      );
    }

    if (content.slice_type === "text") {
      return (
        <ContentContainer
          key={`single_project_item_${index}`}
          className="module-text"
        >
          <div className="module-text-container">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </div>
        </ContentContainer>
      );
    }

    if (content.slice_type === "two_column_text") {
      const items = content.items.map((text, index) => (
        <div
          key={`single_text_item_${index}`}
          className="text"
          dangerouslySetInnerHTML={{
            __html: text.text.html,
          }}
        />
      ));

      return (
        <ContentContainer
          key={`single_project_item_${index}`}
          className="module-two-column-text"
        >
          <div
            className={`module-text-with-image-container image-${content.primary.image_position.toLowerCase()}  text-columns-${
              items.length
            }`}
          >
            <div className="image-container">
              {content.primary.image.fluid !== null && (
                <AspectRatioImageContainer image={content.primary.image}>
                  <img
                    srcSet={content.primary.image.fluid.srcSetWebp}
                    src={content.primary.image.fluid.srcWebp}
                    alt={content.primary.image.alt}
                    loading={`lazy`}
                  />
                </AspectRatioImageContainer>
              )}
            </div>

            <div className="text-container">{items}</div>
          </div>
        </ContentContainer>
      );
    }
  });

  return (
    <>
      <PageSEO
        title={
          data.prismicArticle.data.seo_title !== null
            ? data.prismicArticle.data.seo_title
            : data.prismicArticle.data.title.text
        }
        image={
          data.prismicArticle.data.seo_image !== null
            ? data.prismicArticle.data.seo_image.url
            : null
        }
        description={data.prismicArticle.data.seo_description}
        url={`https://mcmullanstudio.com${data.prismicArticle.url}`}
      />
      <Page id="article">
        <MobilePageTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicArticle.data.title.html,
            }}
          />
        </MobilePageTitle>
        {content}
      </Page>
    </>
  );
};

export default withPreview(Article);

export const query = graphql`
  query Article($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      url
      data {
        title {
          html
          text
        }
        seo_title
        seo_image {
          url
        }
        seo_description
        body {
          ... on PrismicArticleBodyImage {
            id
            slice_type
            primary {
              image {
                dimensions {
                  width
                  height
                }
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
                alt
              }
              caption {
                html
              }
            }
          }
          ... on PrismicArticleBodyVideo {
            id
            slice_type
            primary {
              video {
                embed_url
                html
                width
                height
              }
              caption {
                html
              }
            }
          }
          ... on PrismicArticleBodyTwoColumnText {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
                dimensions {
                  width
                  height
                }
              }
              image_position
            }
            items {
              text {
                html
              }
            }
          }
          ... on PrismicArticleBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicArticleBodyFlipbookEmbed {
            id
            slice_type
            primary {
              embed_code {
                text
              }
            }
          }
        }
      }
    }
  }
`;
